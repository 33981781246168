<script setup lang="ts">
import { useCurrentUser, useFirebaseAuth } from "vuefire";
import { useQueryClient } from "@tanstack/vue-query";
import { useMediaQuery } from "@vueuse/core";
import { ChevronDown } from "lucide-vue-next";

const nuxtApp = useNuxtApp();

const { isLoading, data, suspense, isFetched } =
  useCurrentUserSubscriptionQuery();

watch(isFetched, async () => {
  if (isFetched.value) {
    const locale = data.value.locale;

    if (locale) {
      const baseRoute = nuxtApp.$getRouteBaseName();
      await navigateTo(nuxtApp.$localePath(baseRoute, locale));
    }
  }
  console.log(isFetched);
});

const queryClient = useQueryClient();

onServerPrefetch(async () => {
  await suspense();
});

const user = useCurrentUser();

const auth = useFirebaseAuth();

function logout() {
  auth.signOut().finally(async () => {
    nuxtApp.$intercom.trackEvent("Logged out");
    await queryClient.clear();
    await navigateTo(nuxtApp.$localePath("/login"));
  });
}

const modalStore = useModalStore();

const i18n = useI18n();

const isOpen = ref(false);
const isDrawerOpen = ref(false);
const isDesktop = useMediaQuery("(min-width: 768px)");

const openUpgradeModal = () => {
  modalStore.openSubscribeOrUpgrade({ source: "Account nav" });
  isDrawerOpen.value = false;
  isOpen.value = false;
};
</script>

<template>
  <link
    v-for="l in getLocales()"
    rel="preload"
    as="image"
    :href="`/flags/langs/${l.value}.svg`"
  />
  <NavigationMenu
    v-if="isDesktop"
    v-model="isOpen"
    :disable-hover-trigger="true"
  >
    <NavigationMenuList>
      <NavigationMenuItem>
        <NavigationMenuTrigger>
          <div
            class="flex flex-col items-end flex-shrink-1 flex-grow-0"
            v-if="!isLoading"
          >
            <Text
              variant="body_strong"
              class="font-bold text-mono-black capitalize text-ellipsis max-w-[200px] whitespace-nowrap overflow-hidden"
              >{{ data?.fullname }}</Text
            >
          </div>

          <template v-slot:end>
            <CircleAvatar
              class="flex-shrink-0"
              :isLoading="isLoading"
              :initials="data?.initial"
            ></CircleAvatar> </template
        ></NavigationMenuTrigger>
        <NavigationMenuContent>
          <ul class="w-[320px] p-4">
            <NuxtLink
              @click="isOpen = false"
              :to="localePath('/account/details')"
              class="flex flex-row mb-2 font-semibold items-center cursor-pointer justify-start select-none rounded-md py-2 px-5 leading-none no-underline outline-none transition-colors hover:bg-mono-off-white hover:text-mono-black focus:bg-mono-off-white focus:text-mono-black"
            >
              <div class="flex flex-row">
                <CircleAvatar
                  size="lg"
                  :isLoading="isLoading"
                  :initials="data?.initial"
                  class="mr-4 flex-shrink-0"
                ></CircleAvatar>
                <div class="flex flex-col justify-center break-all">
                  <Text variant="body_strong_lg"> {{ data?.fullname }} </Text>
                  <Text variant="body_sm"> {{ user?.email }}</Text>
                </div>
              </div>
            </NuxtLink>
            <div
              v-if="data?.planLimit !== 'Unlimited'"
              class="w-full flex flex-grow flex-row justify-between bg-primary-light rounded-lg p-5 mb-[5px]"
            >
              <div>
                <Text variant="body_strong_sm">{{
                  $t("nav.subscripton")
                }}</Text>
                <Text variant="body_sm" class="font-semibold mt-1"
                  >{{
                    data?.allowUnlimitedDrafts
                      ? data?.numberPublishedLabels
                      : data?.numberActiveLabels
                  }}
                  / {{ data?.planLimit }} {{ $t("nav.labels-used") }}</Text
                >
              </div>
              <TextLink @click="openUpgradeModal" size="sm">{{
                $t("nav.upgrade-plan")
              }}</TextLink>
            </div>

            <div
              v-if="data?.numberRedirectedLabels"
              class="w-full flex flex-grow flex-row justify-between bg-mono-off-white rounded-lg px-5 py-2 mt-[5px] mb-[5px]"
            >
              <div class="flex flex-row items-center">
                <font-awesome class="h-[16px] mr-2" icon="diamond-turn-right" />
                <Text variant="body_sm" class="font-semibold">
                  <span v-if="data?.numberRedirectedLabels == 1">
                    {{ $t("nav.1-redirection") }}
                  </span>
                  <span v-else>
                    {{
                      $t("nav.plural-redirections", [
                        data?.numberRedirectedLabels,
                      ])
                    }}</span
                  >
                </Text>
              </div>
            </div>

            <div
              v-if="data?.numberLockedLabels"
              class="w-full flex flex-grow flex-row justify-between bg-destructive-light rounded-lg px-5 py-2 mt-[5px] mb-[5px] text-destructive"
            >
              <div class="flex flex-row items-center">
                <font-awesome class="h-[16px] mr-2" icon="lock" />
                <Text
                  variant="body_sm"
                  color="destructive"
                  class="font-semibold"
                  ><span v-if="data?.numberLockedLabels == 1">
                    {{ $t("nav.1-locked") }}
                  </span>
                  <span v-else>
                    {{ $t("nav.plural-locked", [data?.numberLockedLabels]) }}
                  </span></Text
                >
              </div>
            </div>

            <li class="mt-2">
              <NavigationMenuLink as-child>
                <a
                  @click="modalStore.openChangeLanguage"
                  class="flex flex-row font-semibold items-center justify-start select-none rounded-md p-5 leading-none no-underline outline-none transition-colors hover:bg-mono-off-white cursor-pointer hover:text-mono-black focus:bg-mono-off-white focus:text-mono-black"
                >
                  <font-awesome icon="globe" class="mr-3 text-[18px]" />
                  <div
                    class="flex flex-row flex-grow justify-between items-center"
                  >
                    {{ getNameForLocale(i18n.locale.value) }}
                    <LanguageFlag :locale="i18n.locale.value" />
                  </div>
                </a>
              </NavigationMenuLink>

              <NuxtLink
                @click="isOpen = false"
                :to="localePath('/account')"
                class="flex flex-row font-semibold items-center cursor-pointer justify-start select-none rounded-md p-5 leading-none no-underline outline-none transition-colors hover:bg-mono-off-white hover:text-mono-black focus:bg-mono-off-white focus:text-mono-black"
              >
                <NavigationMenuLink as-child>
                  <font-awesome icon="credit-card" class="mr-3 text-[18px]" />

                  {{ $t("nav.billing") }}
                </NavigationMenuLink>
              </NuxtLink>

              <NuxtLink
                @click="isOpen = false"
                :to="localePath('/account/settings')"
                class="flex flex-row font-semibold items-center cursor-pointer justify-start select-none rounded-md p-5 leading-none no-underline outline-none transition-colors hover:bg-mono-off-white hover:text-mono-black focus:bg-mono-off-white focus:text-mono-black"
              >
                <NavigationMenuLink as-child>
                  <font-awesome icon="gear" class="mr-3 text-[18px]" />

                  {{ $t("nav.settings") }}
                </NavigationMenuLink>
              </NuxtLink>

              <NavigationMenuLink>
                <a
                  @click="isOpen = false"
                  href="https://help.pinotqr.com"
                  target="_blank"
                  rel="nofollower noreferrer"
                  class="flex flex-row font-semibold items-center cursor-pointer justify-start select-none rounded-md p-5 leading-none no-underline outline-none transition-colors hover:bg-mono-off-white hover:text-mono-black focus:bg-mono-off-white focus:text-mono-black"
                >
                  <font-awesome icon="life-ring" class="mr-3 text-[18px]" />

                  {{ $t("nav.help") }}
                </a>
              </NavigationMenuLink>

              <NavigationMenuLink>
                <a
                  @click="logout"
                  class="flex flex-row font-semibold items-center cursor-pointer justify-start select-none rounded-md p-5 leading-none no-underline outline-none transition-colors hover:bg-mono-off-white hover:text-mono-black focus:bg-mono-off-white focus:text-mono-black"
                >
                  <font-awesome
                    icon="arrow-right-from-bracket"
                    class="mr-3 text-[18px]"
                  />

                  {{ $t("nav.sign-out") }}
                </a>
              </NavigationMenuLink>
            </li>
          </ul>
        </NavigationMenuContent>
      </NavigationMenuItem>
    </NavigationMenuList>
  </NavigationMenu>
  <Drawer v-model:open="isDrawerOpen" v-else>
    <DrawerTrigger>
      <div
        class="group flex-shrink-1 inline-flex w-max items-center justify-center rounded-full bg-mono-off-white pl-5 pr-2 py-2 text-sm text-mono-dark-grey font-medium transition-colors hover:bg-mono-white hover:ring-2 hover:ring-mono-silver hover:text-mono-black focus:outline-none focus:ring-2 focus:ring-mono-silver focus:text-mono-black disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-mono-white data-[state=open]:bg-mono-white data-[state=open]:ring-2 data-[state=open]:ring-mono-black transition-all"
      >
        <ChevronDown
          class="relative top-px mr-3 h-5 w-5 transition stroke-[3px] duration-200"
          :class="isDrawerOpen && 'rotate-180'"
          aria-hidden="true"
        />

        <CircleAvatar
          :isLoading="isLoading"
          :initials="data?.initial"
        ></CircleAvatar>
      </div>
    </DrawerTrigger>
    <DrawerContent>
      <ul class="w-full text-mono-dark-grey">
        <NuxtLink
          @click="isDrawerOpen = false"
          :to="localePath('/account/details')"
          class="flex flex-row mb-2 font-semibold items-center cursor-pointer justify-start select-none rounded-md mt-2 px-5 py-4 leading-none no-underline outline-none transition-colors hover:bg-mono-off-white hover:text-mono-black focus:bg-mono-off-white focus:text-mono-black"
        >
          <div class="flex flex-row">
            <CircleAvatar
              size="lg"
              :isLoading="isLoading"
              :initials="data?.initial"
              class="mr-4 flex-shrink-0"
            ></CircleAvatar>
            <div class="flex flex-col justify-center break-all">
              <Text variant="body_strong_lg"> {{ data?.fullname }} </Text>
              <Text variant="body_sm" class="text-ellipsis overflow-hidden">
                {{ user?.email }}</Text
              >
            </div>
          </div>
        </NuxtLink>
        <div
          v-if="data?.planLimit !== 'Unlimited'"
          class="w-full flex flex-grow flex-row justify-between bg-primary-light rounded-lg p-5 mb-[5px]"
        >
          <div>
            <Text variant="body_strong_sm">{{ $t("nav.subscripton") }}</Text>
            <Text variant="body_sm" class="mt-1"
              >{{
                data?.allowUnlimitedDrafts
                  ? data?.numberPublishedLabels
                  : data?.numberActiveLabels
              }}
              / {{ data?.planLimit }} {{ $t("nav.labels-used") }}</Text
            >
          </div>
          <TextLink @click="openUpgradeModal" size="sm">{{
            $t("nav.upgrade-plan")
          }}</TextLink>
        </div>

        <div
          v-if="data?.numberRedirectedLabels"
          class="w-full flex flex-grow flex-row justify-between bg-mono-off-white rounded-lg px-5 py-2 mt-[5px] mb-[5px]"
        >
          <div class="flex flex-row items-center">
            <font-awesome class="h-[16px] mr-2" icon="diamond-turn-right" />
            <Text variant="body_sm" v-if="data?.numberRedirectedLabels == 1">{{
              $t("nav.1-redirection")
            }}</Text>
            <Text variant="body_sm" v-else>{{
              $t("nav.plural-redirections", [data?.numberRedirectedLabels])
            }}</Text>
          </div>
        </div>

        <div
          v-if="data?.numberLockedLabels"
          class="w-full flex flex-grow flex-row justify-between bg-destructive-light rounded-lg px-5 py-2 mt-[5px] mb-[5px] text-destructive"
        >
          <div class="flex flex-row items-center">
            <font-awesome class="h-[16px] mr-2" icon="lock" />
            <Text variant="body_sm" color="destructive"
              ><span v-if="data?.numberLockedLabels == 1">
                {{ $t("nav.1-locked") }}
              </span>
              <span v-else>
                {{ $t("nav.plural-locked", [data?.numberLockedLabels]) }}
              </span></Text
            >
          </div>
        </div>

        <li class="mt-2">
          <NavigationMenuLink @click="isDrawerOpen = false" as-child>
            <a
              @click="modalStore.openChangeLanguage"
              class="flex flex-row font-semibold items-center justify-start select-none rounded-md p-5 leading-none no-underline outline-none transition-colors hover:bg-mono-off-white cursor-pointer hover:text-mono-black focus:bg-mono-off-white focus:text-mono-black"
            >
              <font-awesome icon="globe" class="mr-3 text-[18px]" />
              <div class="flex flex-row flex-grow justify-between items-center">
                {{ getNameForLocale(i18n.locale.value) }}
                <LanguageFlag :locale="i18n.locale.value" />
              </div>
            </a>
          </NavigationMenuLink>

          <NuxtLink
            @click="isDrawerOpen = false"
            :to="localePath('/account')"
            class="flex flex-row font-semibold items-center cursor-pointer justify-start select-none rounded-md p-5 leading-none no-underline outline-none transition-colors hover:bg-mono-off-white hover:text-mono-black focus:bg-mono-off-white focus:text-mono-black"
          >
            <NavigationMenuLink as-child>
              <font-awesome icon="credit-card" class="mr-3 text-[18px]" />

              {{ $t("nav.billing") }}
            </NavigationMenuLink>
          </NuxtLink>

          <NuxtLink
            @click="isDrawerOpen = false"
            :to="localePath('/account/settings')"
            class="flex flex-row font-semibold items-center cursor-pointer justify-start select-none rounded-md p-5 leading-none no-underline outline-none transition-colors hover:bg-mono-off-white hover:text-mono-black focus:bg-mono-off-white focus:text-mono-black"
          >
            <NavigationMenuLink as-child>
              <font-awesome icon="gear" class="mr-3 text-[18px]" />

              {{ $t("nav.settings") }}
            </NavigationMenuLink>
          </NuxtLink>

          <NavigationMenuLink>
            <a
              @click="isDrawerOpen = false"
              href="https://help.pinotqr.com"
              target="_blank"
              rel="nofollower noreferrer"
              class="flex flex-row font-semibold items-center cursor-pointer justify-start select-none rounded-md p-5 leading-none no-underline outline-none transition-colors hover:bg-mono-off-white hover:text-mono-black focus:bg-mono-off-white focus:text-mono-black"
            >
              <font-awesome icon="life-ring" class="mr-3 text-[18px]" />

              {{ $t("nav.help") }}
            </a>
          </NavigationMenuLink>

          <NavigationMenuLink>
            <a
              @click="logout"
              class="flex flex-row font-semibold items-center cursor-pointer justify-start select-none rounded-md p-5 leading-none no-underline outline-none transition-colors hover:bg-mono-off-white hover:text-mono-black focus:bg-mono-off-white focus:text-mono-black"
            >
              <font-awesome
                icon="arrow-right-from-bracket"
                class="mr-3 text-[18px]"
              />

              {{ $t("nav.sign-out") }}
            </a>
          </NavigationMenuLink>
        </li>
      </ul>
    </DrawerContent>
  </Drawer>
</template>
